import React from "react"
import Layout from "../components/layout"

import SEO from "../components/seo"
import Cta from "../components/cta"
import LatestProject from "../components/latest-project"
import Experience from "../components/experience"

const ServicesPage = () => (
  <Layout>
    <SEO title="Home" />
    <Cta />
    <LatestProject />
    <Experience />
    {/* <PeopleSays /> */}
  </Layout>
)

export default ServicesPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
